import React from 'react';
//import Layout from '../components/layout';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import SEO from '../components/seo';
import PastExec from '../components/Committes/pastexec';

const Past = () => {
    return (
        <>
            <SEO title="Past Executive Committee"/>
            <MDBContainer className="mt-4">
            <h3 className="title font-weight-bolder text-secondary">Past Executive Committee</h3>
                <Pageheader />
                <PastExec />
            </MDBContainer>
        </>
    );
};

export default Past;