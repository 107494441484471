import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBadge, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import { useStaticQuery, graphql } from 'gatsby';
const PastExec = () => {
    const list = useStaticQuery(graphql`
    {
        allPastJson(sort: {fields: Year, order: DESC}) {
            edges {
                node {
                    id
                    Year
                    ChairPerson
                    ViceChairPerson
                    Treasurer
                    Secretary
                    ProgramCommitteeChair_Co_Chair
                    PublicRelationandCommunityServiceChair_Co_Chair
                    HospitalityCommitteeChair_Co_Chair
                    FundRaisingCommitteeChair_Co_Chair
                    FacilitiesandMaintenanceChair_Co_Chair
                    YouthandAdultEducationCommitteeChair_Co_Chair
                }
            }
        }
    }`);;
    const nodeData = list.allPastJson.edges;
    const pastexecutiveDataItems = nodeData.map((edge, index) => {

        return (
            <MDBCard style={{ width: "100%", float: "left" }} className="m-2" key={index}>
                <MDBCardBody>
                    <MDBBadge color="info" className="my-2 mx-2" style={{ fontSize: "large" }} key={index}>{edge.node.Year}</MDBBadge>

                    <MDBCardText>
                        <span className="lead font-weight-bold text-info">Chair Person:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.ChairPerson} </span><br></br>
                        <span className="lead font-weight-bold text-info">Vice Chair Person:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.ViceChairPerson} </span><br></br>
                        <span className="lead font-weight-bold text-info">Treasurer:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.Treasurer} </span><br></br>
                        <span className="lead font-weight-bold text-info">Secretary:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.Secretary} </span><br></br>
                        <span className="lead font-weight-bold text-info">Program Committee Chair/CoChair:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.ProgramCommitteeChair_Co_Chair ? edge.node.ProgramCommitteeChair_Co_Chair : "N/A"} </span><br></br>
                        <span className="lead font-weight-bold text-info">Public Relation & Community Service Chair/CoChair:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.PublicRelationandCommunityServiceChair_Co_Chair ? edge.node.PublicRelationandCommunityServiceChair_Co_Chair : "N/A"} </span><br></br>
                        <span className="lead font-weight-bold text-info">Fund Raising Committee Chair/CoChair:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.FundRaisingCommitteeChair_Co_Chair ? edge.node.FundRaisingCommitteeChair_Co_Chair : "N/A"} </span><br></br>
                        <span className="lead font-weight-bold text-info">Facilities & Maintenance Chair/CoChair:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.FacilitiesandMaintenanceChair_Co_Chair ? edge.node.FacilitiesandMaintenanceChair_Co_Chair : "N/A"} </span><br></br>
                        <span className="lead font-weight-bold text-info">Hospitality Committee Chair/CoChair:</span>  <span className="lead font-weight-bolder text-primary">{edge.node.HospitalityCommitteeChair_Co_Chair ? edge.node.HospitalityCommitteeChair_Co_Chair : "N/A"} </span><br></br>
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard >


        );
    });

    return (
        <MDBContainer className="mx-4 px-4 mt-4">
            <MDBRow >
                <MDBCol md="12">
                    {pastexecutiveDataItems}
                </MDBCol>
            </MDBRow>
        </MDBContainer>

    );
}
export default PastExec;
